import './assets/scss/style.scss'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'
// import { createMetaManager } from "vue-meta";
// import VueMeta from 'vue-meta'
import {createHead} from '@vueuse/head'


const head = createHead()

const app = createApp(App)

// app.use(createMetaManager());
app.use(router)
app.use(head)
app.use(i18n)

app.mount('#app')
