import i18n from "@/plugins/i18n";
import HomeView from "@/views/HomeView";

const routes = [
    {
        path: '/',
        redirect: `/${i18n.global.locale.value}`,
    },
    {
        path: '/:locale',
        component: {template: `<RouterView />`},
        beforeEnter: (to, from, next) => {
            const locale = to.params.locale
            const supportedLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');

            if (!supportedLocales.includes(locale)) return next('en')

            if (i18n.global.locale.value !== locale) {
                i18n.global.locale.value = locale
            }

            return next()
        },

        children: [
            {
                path: '',
                name: 'home',
                meta: {
                    title: '',
                },
                component: HomeView
            },
            {
                path: 'cases',
                name: 'cases',
                meta: {
                    title: 'Кейсы',
                    breadcrumb: i18n.global.t('nav.cases')
                },
                component: () => import('../views/CasesView.vue')
            },
            {
                path: 'solutions',
                name: 'solutions',
                meta: {
                    title: 'Решения',
                    breadcrumb: i18n.global.t('nav.solutions')
                },
                component: () => import('../views/SolutionsView.vue')
            },
            {
                path: 'services',
                name: 'services',
                meta: {
                    title: 'Услуги',
                    breadcrumb: i18n.global.t('nav.services')
                },
                component: () => import('../views/ServicesView.vue')
            },
            {
                path: 'contacts',
                name: 'contacts',
                meta: {
                    title: 'Контакты',
                    breadcrumb: i18n.global.t('nav.contacts')
                },
                component: () => import('../views/ContactsView.vue')
            },
            {
                path: 'creative-lab',
                name: 'creative-lab',
                meta: {
                    title: 'Креативная лаборатория',
                    breadcrumb: i18n.global.t('nav.creative-lab')
                },
                component: () => import('../views/CreativeLabView.vue')
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        meta: {
            title: '404'
        },
        component: () => import('../views/NotFoundView.vue')
    }
]

export default routes
