export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Технологичное агентство мобильного маркетинга"])},
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кейсы"])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решения"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуги"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "creative-lab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативная лаборатория"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами"])}
  },
  "introHome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Технологичное агентство мобильного маркетинга"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы помогаем бизнесу находить новых клиентов"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 лет экспертизы на рынке помогают нам погрузиться в проект, разработать и реализовать для вас комплексную стратегию по привлечению новых пользователей."])}
  },
  "introCases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши кейсы"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реальные проекты с отличными результатами"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша миссия — помогать расти вашему бизнесу, используя всю мощь мобильных устройств и современных рекламных технологий."])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши клиенты"])}
  },
  "introSolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медиабаинг"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правильные решения для достижения лучших результатов"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы работаем только с качественными источниками трафика, которые приносят максимальную выгоду нашим клиентам."])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши источники"])}
  },
  "introServices": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши услуги"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продвижение мобильных приложений"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы закрываем полный спектр ваших потребностей в маркетинге мобильных приложений, начиная с разработки стратегии и заканчивая оптимизацией конверсий с каждого баннера."])}
  },
  "sectionList": {
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru In-App Network"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru In-App Network работает с крупнейшими рекламными платформами и издателями игр. Мы сгенерировали более 1 миллиона лидов и обеспечили более 500 миллион регистраций за год."])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медиабаинг"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["150 медиабаеров и собственная технология по оптимизации трафика гарантируют качественных юзеров и возврат инвестиций в короткие сроки."])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнать Больше"])}
  },
  "whywe": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почему вам стоит выбрать нас?"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru — международный проект и существует на рынке уже 8 лет, а также является лидером в разработке и продвижении игровых и неигровых мобильных приложений в вертикалях e-com, потокового видео, доставки еды, спорта, коммунальных услуг, финансов."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с нами"])}
  },
  "contactsBlock": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всегда на связи!"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остались вопросы?"])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами!"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просто оставьте заявку — и мы сделаем такие же вовлекающие креативы для вас."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы хотите получить больше информации о нас, заполните форму для сообщения или свяжитесь с нами любым другим удобным для вас способом."])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["телефон"])},
    "vk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вконтакте"])}
  },
  "feedbackForm": {
    "errorField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните это поле"])},
    "errorMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите правильный email"])},
    "label": {
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш контакт (email, skype, telegram и т.п.)"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше сообщение"])},
      "policyCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я согласен на обработку моих персональных данных в маркетинговых целях в соответствии с Политикой конфиденциальности"])},
      "newsLettersCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласен получать информационные рассылки от MobiGuru"])}
    },
    "isMessageSended": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы получили вашу заявку и в ближайшее время мы свяжемся с вами!"])}
      },
      "fail": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По техническим причинам ваше сообщение не может быть доставлено."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте еще раз чуть позже."])}
      }
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить сообщение"])}
  },
  "footer": {
    "politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])}
  },
  "cases": {
    "okko": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKKO"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-кинотеатр с более чем 90 000 фильмов, мультфильмов, сериалов, спортивных программ и трансляций."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["установок"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистраций"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CR/год"])}
      }
    },
    "delimobil": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delimobil"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Российский сервис краткосрочной аренды автомобилей, запущенный в 2015 году. «Делимобиль» работает в ряде городов России, численность его автопарка составляет более 15 000 автомобилей."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистраций/год"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["поездок/год"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["охват"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все города присутствия Делимобиля на территории РФ"])}
      }
    },
    "winline": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winline"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Динамично развивающийся российский букмекер. На рынке букмекерских услуг они появились в 2009 году. За столь короткий промежуток времени БК Winline прошла большой путь в своем развитии и приобрела известность на рынке букмекерских услуг стран СНГ."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистраций/год"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["первых депозитов/год"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вторых депозитов/год"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["присутствие в YouTubе, категория спорт"])}
      }
    },
    "stoloto": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Столото"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Насчитывает более 25 тысяч точек по России. Лотерейные продукты, распространяемые ТД «Столото», продаются во всех регионах России. Организаторами «Столото» являются Министерство финансов РФ и Министерство спорта РФ."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистраций/год"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фокус/Кампания с одним каналом коммуникации в TikTok"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награда Effie 2021"])}
      }
    }
  },
  "ourstat": {
    "name1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["источников трафика"])},
    "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["установок в год"])},
    "name3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["стран мира"])}
  },
  "process": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процесс"])},
    "step1": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаг 1"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь видит рекламу приложения в одном из наших источников"])}
    },
    "step2": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаг 2"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажимает на объявление и попадает на страницу маркетплейса"])}
    },
    "step3": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаг 3"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачивает <br>приложение"])}
    },
    "step4": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаг 4"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открывает приложение и регистрируется"])}
    }
  },
  "benefits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши преимущества"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизация"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Технологии по оптимизации трафика позволяют получить максимальную прибыль от инвестиций."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креатив"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативный отдел знает, с помощью каких подходов привлечь и “зацепить” вашу целевую аудиторию."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифрод"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственная антифрод система защищает наших клиентов от мошенничества и некачественного трафика."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качество и объемы"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За счет накопленной экспертизы генерируем большие объемы качественного трафика в сжатые сроки."])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентский сервис"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прозрачный подход к работе, еженедельные отчеты и выделенный менеджер позволяют предоставлять сервис на высшем уровне."])}
  },
  "performance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance-маркетинг приложений"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В концепции нашей работы лежит Performance-маркетинг — продвижение приложений брендов без абстрактных цифр, с точными результатами и достижением KPI, а также возврат инвестиций (ROI) в короткие сроки. Данный принцип идеально подходит для тех, кто не хочет тратить деньги впустую."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почему вам может быть интересен Performance-маркетинг"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чаще всего Performance-маркетинг используется при бюджете на рекламу в 200 тысяч рублей и более. Хотя и при более скромном финансировании с его помощью можно орудовать базовыми инструментами: например, отслеживать, какое финансирование требуется тому или иному каналу, или смотреть статистику по эффективности инструментов."])}
  },
  "performanceHelp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С помощью Performance-маркетинга можно:"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измерить результаты эксплуатируемых каналов по рекламе"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценить бизнес-показатели: CPA и ROI"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В реальном времени повлиять на результаты продвижения"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизировать работу всех каналов продвижения (SEO, SMM, email-рассылки, реклама в СМИ)"])}
  },
  "performanceAso": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASO (App Store оптимизация)"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASO или App Store Optimization – это комплексный процесс оптимизации страницы мобильного приложения в магазинах App Store и Google Play с целью максимизации его видимости и конверсии посетителей в скачивания, и  включает в себя полную текстовую оптимизацию название, описания приложения, а также графических материалов."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В контексте работы с продвижением приложения, ASO - это фундамент. <br> Чем детальнее и тщательнее он проработан, тем лучший результат по рекламным кампаниям можно получить в дальнейшем. <br> ASO решает основные задачи бизнеса: <br> — Увеличение видимости приложения; <br> — Увеличение конверсии; <br> — Улучшение рейтинга."])}
  },
  "performanceAsoBenefits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что вы получите после проведения ASO?"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индексацию по ключевым словам: ваше приложение легко найти в поиске магазинов приложений по разным ключевым словам."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Высокие показатели органических установок и их планомерный рост: все инструменты ASO напрямую влияют на органический трафик."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меньшую подверженность изменениям условий сторов: у каждого магазина приложений свои особенности, влияющие на стабильность ASO показателей."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Визуальные элементы показывают хорошую конверсию: пользователь легко идентифицирует ваше приложение в конкурентном поле, быстрее захватывая основную информацию."])}
  },
  "creativeLab": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативная лаборатория Mobi<span>Guru</span>"])},
    "info1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дизайн"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативы определяют 50% успеха рекламной кампании"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка гипотез позволяет увеличивать вовлеченность"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преимущество большого опыта на рынке выделяют нас среди конкурентов"])}
    },
    "info2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продакшн"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурсы для поддержки ваших идей (большой штат дизайнеров и т.д.)"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достаточный объем мощностей, поэтому кампании никогда не останавливаются"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статические изображения, баннеры, 2D-видео, 3D-видео и т.д."])}
    }
  },
  "metaHome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru - Профессиональное продвижение мобильных приложений"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы предлагаем комплексные решения для привлечения новых пользователей и увеличения видимости вашего приложения. Наша команда экспертов обеспечит вам качественные результаты и быстрый возврат инвестиций."])}
  },
  "metaCases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru - Успешные кейсы в мобильном маркетинге"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши кейсы демонстрируют, как мы используем инновационные подходы и технологии для достижения впечатляющих результатов в мобильном маркетинге."])}
  },
  "metaSolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реклама мобильных приложений с MobiGuru - достижение лучших результатов"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru предлагает эффективные решения для рекламы мобильных приложений. Наша команда экспертов использует автоматизацию, креативные подходы и антифрод системы для генерации качественного трафика и обеспечения максимальной прибыли от ваших инвестиций."])}
  },
  "metaServices": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ASO и Перформанс-маркетинг для мобильных приложений"]), _normalize(["MobiGuru"])])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru предлагает комплексные услуги по продвижению мобильных приложений (ASO) и Перформанс-маркетингу. Мы помогаем увеличить видимость вашего приложения в Google Play и App Store, максимизируя конверсию и достигая ваших KPI"])}
  },
  "metaCreativeLab": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru - Профессиональное создание креативов для рекламных кампаний"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативная лаборатория MobiGuru предлагает полный спектр услуг по созданию креативов для рекламных кампаний. Мы создаем статические изображения, баннеры, 2D- и 3D-видео, которые увеличивают вовлеченность и делают вашу рекламу незабываемой"])}
  },
  "metaContacts": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Свяжитесь с нами"]), _normalize(["MobiGuru"])])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В MobiGuru мы всегда на связи и готовы ответить на все ваши вопросы. Вы можете связаться с нами по телефону +7 499 110-07-39, отправить письмо на welcome-MobiGuru.io или написать нам в ВКонтакте."])}
  }
}