<script setup>
import { RouterLink } from 'vue-router';
import { ref, onMounted } from 'vue';
// import LocaleSwitcher from '@/components/LocaleSwitcher';

let isMenuShow = ref(false);

const nav = [
  {
    url: 'cases',
    title: 'Кейсы',
    name: 'cases',
  },
  {
    url: 'solutions',
    title: 'Решения',
    name: 'solutions',
  },
  {
    url: 'services',
    title: 'Услуги',
    name: 'services',
  },
  {
    url: 'creative-lab',
    title: 'Контакты',
    name: 'creative-lab',
  },
  {
    url: 'contacts',
    title: 'Контакты',
    name: 'contacts',
  },
];

const hideMenu = () => {
  if (!isMenuShow.value) return;
  isMenuShow.value = !isMenuShow.value;
};

const isFixed = ref(false);

const scrollHandler = () => {
  if (!isFixed.value && window.scrollY) {
    isFixed.value = true;
  }

  if (isFixed.value && !window.scrollY) {
    isFixed.value = false;
  }
};

onMounted(() => {
  window.addEventListener('scroll', scrollHandler);
});

const scrollToForm = () => {
  const form = document.querySelector('#contacts');
  const header = document.querySelector('.header');

  window.scrollTo({
    top: form.offsetTop - header.getClientRects()[0].height,
    left: 0,
    behavior: 'smooth',
  });
};
</script>

<template>
  <header class="header" :class="{ header_fixed: isFixed }">
    <div class="header__in container">
      <RouterLink class="header__logo" to="/">
        <img src="@/assets/img/logo.svg" alt="mobiguru Logo" />
      </RouterLink>

      <nav class="header__nav" :class="{ header__nav_active: isMenuShow }">
        <RouterLink
          v-for="(item, i) in nav"
          :key="i"
          :to="{ name: item.name, params: { locale: $i18n.locale } }"
          class="header__nav-link"
          active-class="header__nav-link_active"
          @click="hideMenu"
        >
          {{ $t(`nav.${item.name}`) }}
        </RouterLink>
      </nav>
      <!-- <LocaleSwitcher class="header__lang" /> -->
      <div class="header__contact">
        <button class="btn" type="button" @click.prevent="scrollToForm">
          {{ $t(`nav.contactUs`) }}
        </button>
      </div>

      <button
        class="header__menu-btn"
        :class="{ 'header__menu-btn_active': isMenuShow }"
        type="button"
        @click="isMenuShow = !isMenuShow"
      ></button>
    </div>
  </header>
</template>

<style lang="scss">
.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out;

  &_fixed {
    background-color: $color-footer-gray;
  }

  &__in {
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;

    @include s-md {
      //align-items: unset;
      padding-top: 18px;
      padding-bottom: 18px;
    }

    @include s-lg {
      padding-top: 22px;
      padding-bottom: 22px;
    }
  }

  &__logo {
    display: flex;
    img {
      width: 93px;
    }

    @include s-md {
      img {
        width: 117px;
      }

      margin-right: 92px;
    }
  }

  &__nav {
    @include s-max-md {
      display: none;

      &_active {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $color-bgr;

        .header_fixed & {
          background-color: rgba($color-footer-gray, 0.95);
        }
      }
    }
  }

  &__nav-link {
    display: block;
    margin-bottom: 16px;
    padding: 8px 12px;
    font-size: 15px;
    transition-property: color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    text-align: center;
    text-transform: uppercase;

    @include s-md {
      display: inline-block;
      text-align: left;
      text-transform: none;
      margin-bottom: 0;
    }

    &:hover,
    &_active {
      color: $color-black;
    }

    &_active {
      font-weight: 400;
    }
  }

  &__lang {
    margin-right: 24px;
    margin-left: auto;
  }

  &__contact {
    margin-left: auto;
    @include s-max-md {
      display: none;
    }

    @include s-md {
      display: block;
    }
  }

  &__menu-btn {
    @include s-md {
      display: none;
    }

    @include s-max-md {
      width: 24px;
      height: 24px;
      background-image: url('@/assets/img/menu.svg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      margin-left: auto;

      &_active {
        background-image: url('@/assets/img/close.svg');
      }
    }
  }
}
</style>
