export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technological mobile marketing agency."])},
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cases"])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "creative-lab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru Creative Lab"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])}
  },
  "introHome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technological mobile marketing agency."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We help businesses find new customers."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 years of market expertise helps us dive into the project to develop and implement a comprehensive strategy for you to attract new users"])}
  },
  "introCases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our cases"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real projects with great results."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mission is to help your business grow using the full power of mobile devices and modern advertising technologies"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Clients"])}
  },
  "introSolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media buying"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right decisions for the best results."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We work only with high-quality traffic sources that bring the maximum benefit to our clients."])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our sources"])}
  },
  "introServices": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Application Promotion"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cover the full range of your mobile app marketing needs, from strategy development to conversion optimization for each banner."])}
  },
  "sectionList": {
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru In-App Network"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru In-App Network works with the largest advertising platforms and game publishers. We have generated over 1 million leads and secured over 500 million registrations in a year."])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media buying"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["150 media buyers and in-house technology to optimize traffic guarantees quality users and  a good return on investment in a short time."])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Information"])}
  },
  "whywe": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why should you choose us?"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru is an international project and has been in the industry for 8 years. MobiGuru is also a leader in the development and promotion of gaming and non-gaming mobile applications such as e-com, streaming video, food delivery, sports and utilities."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "contactsBlock": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always in touch!"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any remaining questions?"])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us!"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just leave a request and we will create the same engaging creatives for you."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to get more information about us, fill out the form to contact us or reach out in any way that’s convenient for you"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
    "vk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VKontakte"])}
  },
  "feedbackForm": {
    "errorField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty field"])},
    "errorMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email"])},
    "label": {
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your contact (email, skype, telegram, etc)"])},
      "skype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Skype"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
      "policyCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I consent to the processing of my personal data for marketing purposes in accordance with the privacy policy"])},
      "newsLettersCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to receive newsletters from MobiGuru"])}
    },
    "isMessageSended": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your application and we will contact you soon!"])}
      },
      "fail": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For technical reasons, your message could not be delivered."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again later."])}
      }
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a message"])}
  },
  "footer": {
    "politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
  },
  "cases": {
    "okko": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKKO"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An online cinema with more than 90,000 films, cartoons, series, sports programs and broadcasts."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrations"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trial/year"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CR"])}
      }
    },
    "delimobil": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delimobil"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Russian short-term car rental service launched in 2015. Delimobil operates in a number of Russian cities and has a fleet of more than 15,000 vehicles."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrations"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trips"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reach"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All cities where Delimobil is present in the territory of the Russian Federation"])}
      }
    },
    "winline": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winline"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A dynamically developing Russian bookmaker which appeared on the betting market in 2009. In such a short period of time Winline bookmaker has come a long way in its development and gained fame in the betting market of the CIS countries."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrations"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first deposits"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["second deposits"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["presence on YouTube, sports category"])}
      }
    },
    "stoloto": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stoloto"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has more than 25,000 points in Russia. Lottery products distributed by Stoloto Trading House are sold in all regions of Russia. Stoloto is organized by the Ministry of Finance of the Russian Federation and the Ministry of Sports of the Russian Federation."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first purchases"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus/Single Channel TikTok Campaign"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effie Awards 2021"])}
      }
    }
  },
  "ourstat": {
    "name1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["traffic sources"])},
    "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installations per year"])},
    "name3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countries of the world"])}
  },
  "process": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
    "step1": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user sees an ad for the app in one of our sources"])}
    },
    "step2": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicks on the ad and gets to the marketplace page"])}
    },
    "step3": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads <br>the application"])}
    },
    "step4": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opens the app and registers"])}
    }
  },
  "benefits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Benefits"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTOMATION"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traffic optimization technologies allow you to get the maximum return on investment."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATIVE"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The creative department knows what approaches to attract and “hook” your target audience."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANTIFROD"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our own anti-fraud system protects our clients from fraud and low-quality traffic."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUALITY AND VOLUME"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to the accumulated expertise, we generate large volumes of high-quality traffic in a short time."])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER SERVICE"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A transparent approach to work, weekly reports, and a dedicated manager allow us to provide the highest level of service."])}
  },
  "performance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Performance Marketing"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The concept of our work is performance marketing: the promotion of brand applications without abstract numbers, with accurate results and KPI achievement, as well as a return on investment (ROI) in a short time. This principle is ideal for those who do not want to waste money."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why you might be interested in performance marketing"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most often, performance marketing is used with an advertising budget of 200,000 rubles or more. Although even with more modest funding, it can be used to operate basic tools, such as tracking what funding is required for a particular channel, or viewing statistics on the effectiveness of tools."])}
  },
  "performanceHelp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With performance marketing you can:"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measure the results of operated channels for advertising"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate business indicators: CPA and ROI"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influence promotion results in real time"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronize the work of all promotion channels (SEO, SMM, email newsletters, media advertising)"])}
  },
  "performanceAso": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASO (App Store Optimization)"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASO or App Store Optimization is a comprehensive process of optimizing a mobile application page in the App Store and Google Play stores in order to maximize its visibility and conversion of visitors to downloads. It includes full text optimization of the title and the description of the application, as well as graphic materials."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the context of working with app promotion, ASO is the foundation.<br> The more detailed and thorough it is, the better the result of advertising campaigns that can be obtained in the future.<br> ASO solves the main business tasks:<br>— Increase the visibility of the application;<br>— Increase in conversion;<br>— Rating improvement."])}
  },
  "performanceAsoBenefits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What will you get after the ASO?"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword Indexing: Your app is easily found in app store searches for various keywords."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High organic install rates and steady growth: All ASO tools have a direct impact on organic traffic."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less exposure to changes in store conditions: each app store has its own characteristics that affect the stability of ASO indicators."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visual elements show a good conversion: the user easily identifies your application in a competitive field, capturing basic information faster."])}
  },
  "creativeLab": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobi<span>Guru</span> Creative Lab"])},
    "info1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatives determine 50% of ad campaign success"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothesis testing allows you to increase engagement"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The advantage of extensive experience in the market distinguish us from competitors"])}
    },
    "info2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources to back your ideas (large team of designers and etc.)"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enough volume so campaigns are constantly running and never stop"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Static, banners, 2d video, 3d video, playable ads & UGC ads etc"])}
    }
  },
  "metaHome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru - Expert Mobile Marketing Solutions for Your Business"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru: Expert mobile marketing for reaching goals ✅ Tech-driven, targeting your audience effectively"])}
  },
  "metaCases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru - Successful Case Studies in Mobile Marketing"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru: Empowering growth via mobile. OKKO, Delimobil, Stoloto - our success stories speak"])}
  },
  "metaSolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehensive Mobile Advertising Agency- MobiGuru"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovative mobile marketing by MobiGuru ✅ High-quality traffic, anti-fraud systems, top-tier services"])}
  },
  "metaServices": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Comprehensive App Marketing and ASO Services"]), _normalize(["MobiGuru"])])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru: ASO specialists for App Store & Google Play ✅ Maximize ROI with our full-range services"])}
  },
  "metaCreativeLab": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru Creative Lab - Design and Production for Successful Ad Campaigns"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiGuru Creative Lab: Your ad success partner ✅ From static to 3D, we create engaging formats"])}
  },
  "metaContacts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact MobiGuru - Your Mobile Marketing Partner"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact MobiGuru for tech-based mobile marketing ✅ Grow your business now! Call +7 499 110-07-39 or visit welcome-MobiGuru.io"])}
  }
}